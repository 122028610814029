//
// cta.scss
//


.bg-cta{
    background-image: url(../images/detay.jpg);
    background-attachment: fixed;
    background-size: cover;
    position: relative;
    background-position: center;
}

